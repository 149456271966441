import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';

import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'calsoft-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
  @Input() url: any;
  @Input() userLoggedIn: any;
  @Input() color: any;

  //logoPositionMobile:boolean = false;
  mobileHeaderHeight: any;
  mobileHeaderLogoGap: any;
  layout: any = 0;
  headerIconColour: any;
  @Input() data: any;
  public serverPath = environment.commonImageApi + "logo/";

  public serverPath3 = environment.commonImageApi + "mobileImage/";

  mobileLogoSrc: any;

  mobileLogoEnable: any = 0;
  disableCartIconInHeader: any = 0;

  disableLoginHeader: any = 0;
  disableWishlistHeader: any = 0;

  constructor(
    private router: Router,
    public cartServices: CartService,
    private dialog: MatDialog,
    private userService: UserService,

    private location: Location,
    public calsoftService: CalsoftService,) { }

  ngOnInit(): void {

    if (this.data != undefined) {
      if (this.data['logoPositionMobile'] != undefined) {
        this.layout = this.data['logoPositionMobile'];
      }

      if (this.data['headerIconColour'] != undefined) {
        this.headerIconColour = this.data['headerIconColour'];
      }

      if (this.data['mobileLogoSrc'] != undefined) {
        this.mobileLogoSrc = this.data['mobileLogoSrc'];
      }

      if (this.data['mobileLogoEnable'] != undefined) {
        this.mobileLogoEnable = this.data['mobileLogoEnable'];
      }

      if (this.data['mobileHeaderHeight'] != undefined) {
        this.mobileHeaderHeight = this.data['mobileHeaderHeight'];
      }


      if (this.data['mobileHeaderLogoGap'] != undefined) {
        this.mobileHeaderLogoGap = this.data['mobileHeaderLogoGap'];
      }


      if (this.data['disableCartIconInHeader'] != undefined) {
        this.disableCartIconInHeader = this.data['disableCartIconInHeader'];
      }


      if (this.data['disableLoginHeader'] != undefined) {
        this.disableLoginHeader = this.data['disableLoginHeader'];
      }

      if (this.data['disableWishlistHeader'] != undefined) {
        this.disableWishlistHeader = this.data['disableWishlistHeader'];
      }



    }



    this.userLogged();

  }
  page1: any;
  public toggleSidebar() {
    this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  }

  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }


  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '790px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }

  }
  back() {

    if (this.router.url.includes('/order/')) {
      this.router.navigate(['/orders'])
    } else {
      this.router.navigate(['/'])
    }

  }


  SignIn() {
    // this.router.navigate(['/sign-in']);
    this.loginModel();
  }


  userLogged() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {

      this.userLoggedIn = true;
      this.cartServices.getCount();

    }
    else {

      this.userLoggedIn = false;
    }
  }





}
