import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from "@angular/router";
import { iif, Observable } from 'rxjs';
import { Constant } from 'src/app/constants/constant';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { UrlService } from 'src/app/services/url-service.service';
import { environment } from 'src/environments/environment';
import { ConfigServiceService } from 'src/app/services/config-service.service';
@Component({
    selector: 'calsoft-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Output() valueChange = new EventEmitter();
    public serverPath2 = environment.commonImageApi + '/categoryBanner/';
    @Input() name: any;
    @Input() value: any;
    @Input() type: any = "'breadCrump'";
    previousUrl: Observable<string> = this.urlService.previousUrl$;
    public pageTitle: string;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];
    categoryDetails: any;
    currentUrl: string;
    navigated: any = false;
    url: boolean;
    visible: boolean;
    displayDescripion: boolean = false;
    displayCategoryImage: boolean = false;
    path: string;
    firstFormGroup: any;
    breadcrumbType:any;


    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title,
        public categoryService: CategoryService,
        private urlService: UrlService,
        private commonService: CommonService,
        private ecryptServiceService: EcryptServiceService,
        public meta: Meta,
        private configService :ConfigServiceService,
    ) {

        this.breadcrumbType = 1;
    }

    ngOnInit() {
        this.getConfigData();
        if (!this.router.url.includes("pr?sid")) {
            this.visible = true;
        } else {
            this.visible = false;
        }


        if (!this.navigated) {
            this.parseRoute(this.activatedRoute.snapshot);
        }
    }

    private parseRoute(node: ActivatedRouteSnapshot) {
        let type = "any";
        let value = this.activatedRoute.snapshot.queryParamMap.get('sid');
        this.urlService.previousUrl$.subscribe((previousUrl: string) => {            
            if (this.router.url.includes("/" + "product") 
            && (previousUrl == null || previousUrl.includes("/" + "search"))) {
                type = "product"
            }  else if (this.router.url.includes("/" + "booking-detail")) {
                type = "product"
                node.data['breadcrumb']="Product"
            }
            else if (previousUrl == null) {
                type = "any";
            }
            else if (!previousUrl.includes("/pr") && this.router.url.includes("/" + "product")) {
                type = "product"
            }else if (this.router.url.includes("/" + "product") && previousUrl.includes("/pr")) {
                var splitted = previousUrl.split("/");

                this.urlService.breadCrumpUrl$.subscribe((categoryName: string) => {
                    value = categoryName;

                });
            }
        });

        if (node.data['breadcrumb']) {
            if (node.url.length) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');
                if (node.params.name == 'Recently Added') {
                    this.breadcrumbs.push({
                        name: "Recently Added Products",
                        url: 'sa'
                    })
                }

                else if (node.params.name && !this.navigated) {
                    this.breadcrumbs.splice(0, 1);

                    if (this.router.url.includes("/product?pid")) {
                        type = 'product';
                        value = this.activatedRoute.snapshot.queryParamMap.get('pid');
                    }
                   else if (this.router.url.includes("/booking-detail/")) {
                        type = 'product';
                        value = this.activatedRoute.snapshot.queryParamMap.get('pid');
                    }

                    else if (type == 'product' || value == null) {
                        type = 'product';
                        value = this.value

                        // if (value.includes("SLS")) {

                        //     value = value.replace(/SLS/g, '/');
                        // }
                        // value = value.replace(/_/g, ' ');
                        // //   value=value.replace(/&/g,'”');
                        // //   value=value.replace(/Ro/g,'®');
                        // value = value.replace(/BRS/g, '(');
                        // value = value.replace(/BREl3jk875/g, ')');

                        // value = value.replace(/"/g, "Por21Ld245");
                        // value = this.ecryptServiceService.encrypt(value);

                        //
                        // value = value.replace(/\//g, "SLS");
                        // value = value.replace(/\%/g, 'xMl3Jk');

                    }

                    if (this.router.url.includes("/type")) {
                        value = this.value
                        type = "product";
                    }
                    if (this.router.url.includes("/product/")) {
                        value = this.activatedRoute.snapshot.params.pid;
                        type = "product";
                    }

                    if (this.router.url.includes("pr?sid")) {
                        value = this.activatedRoute.snapshot.queryParams.sid
                        type = "any";

                    }

                    if (this.router.url.includes("/booking-detail/")) {
                        value = this.activatedRoute.snapshot.params.pid;
                        type = "product";
                    }

                    this.categoryService.categoryById(value, type).subscribe(
                        res => {
                            this.categoryDetails = res;

                            if (this.type == 'product') {
                                this.displayDescripion = this.categoryDetails['displayDescription'] == 1 ? true : false;
                                this.valueChange.emit(this.categoryDetails['productListType'] == 1 ? 'grid' : 'list')

                                if (this.categoryDetails.isCustomValue != null) {

                                    if (this.categoryDetails.isCustomValue.customDesign != null && this.categoryDetails.isCustomValue.customDesign != null) {

                                        if (this.categoryDetails.isCustomValue.landingPage == 1) {
                                            this.displayCategoryImage = true;
                                            this.path = this.serverPath2 + this.categoryDetails.isCustomValue.customDesign;
                                        }


                                    } else {

                                        this.displayCategoryImage = false;
                                    }

                                }
                            }
                            let a = this.categoryDetails['breadCrumpPath'].split(",")
                            let b = this.categoryDetails['path'].split(",")
                            if (this.router.url.includes("/" + "product")) {

                                // node.params.name=this.name

                                a.push(this.name)
                            }
                            if (this.router.url.includes("/booking-detail/")) {

                    

                                a.push(this.name)
                            }
                            for (var i = 0; i < a.length; i++) {

                                if (a.length - 1 == i) {
                                    a[i] = a[i].replace(/_/g, ' ');
                                    a[i] = a[i].replace(/BRSl2jk785/g, '(');
                                    a[i] = a[i].replace(/BREl3jk875/g, ')');
                                    a[i] = a[i].replace("Por21Ld245", '"');
                                    a[i] = a[i].replace(/xMl3Jk/g, '%');
                                    a[i] = a[i].replace(/plusAdd/g, '+');
                                }

                                this.breadcrumbs.push({
                                    name: a[i],
                                    url: b[i] + '/pr'
                                })

                                if (node.params.name.length < 0) {
                                    let name = node.params.name


                                    //name= name.replace(/_/g,' ');



                                    this.breadcrumbs.push({
                                        name: name,
                                        url: "sa"
                                    })
                                }
                            }

                            if (this.router.url.includes("/product/")) {
                                let value = this.breadcrumbs[this.breadcrumbs.length - 1].name

                                this.title.setTitle('Buy ' + value + " Online at Best Price,Order Now");

                                this.meta.addTags([
                                    { name: 'author', content: Constant.siteName },
                                    { name: 'keywords', content: value },
                                ]);


                            }

                            if (this.router.url.includes("/booking-detail/")) {
                                let value = this.breadcrumbs[this.breadcrumbs.length - 1].name
                            }

                        }, error => {

                        }
                    )

                }
                else if (node.data['breadcrumb'] == 'My Order') {
                    this.breadcrumbs.push({
                        name: 'Account Settings',
                        url: '/account/information'
                    })
                    this.breadcrumbs.push({
                        name: "My Orders",
                        url: '/orders'
                    })
                    this.breadcrumbs.push({
                        name: node.params.entityId,
                        url: "sa"
                    })
                }
                else if (node.data['breadcrumb'] == 'Checkout') {
                    this.breadcrumbs = [];
                    this.breadcrumbs.push({
                        name: 'CHECKOUT',
                        url: '/checkout'
                    })

                }
                else if (node.data['breadcrumb'] == 'My Orders') {
                    this.breadcrumbs.push({
                        name: 'Account Settings',
                        url: '/account/information'
                    })
                    this.breadcrumbs.push({
                        name: "My Orders",
                        url: 'sa'
                    })
                }

                else if (node.data['breadcrumb'] == 'pages') {
                    this.activatedRoute.snapshot.params['pageId'];
                    const path = this.router.url; // Get the path segment
                    const splitPath = path.split('/'); // Split the path by '/'
                    let value = splitPath[splitPath.length - 1]; // Get the last segment
                    value = value.replace(/%20/g, ' ');

                    this.breadcrumbs.push({
                        name: value,
                        url: '/'
                    })

                }
                else if (this.router.url.includes('/blog/')) {
                    this.breadcrumbs = [];
                    this.breadcrumbs.push({
                        name: 'Blog',
                        url: '/blog'
                    });
                
                    // const inputString = this.router.url;
                    // const parts = inputString.split('/');
                    // const result = parts[2];
                    // const decodedResult = decodeURIComponent(result); // Decode the URL part to get the original name
                    // const encodedResult = decodedResult.replace(/\+/g, ' '); // Replace the "+" with spaces
                
                    let value=this.activatedRoute.snapshot.queryParams.title;
                    this.breadcrumbs.push({
                        name: value, // Use the properly decoded name in the breadcrumb
                        url: '/blog/' + value // Use the original URL part in the breadcrumb URL
                    });
                }
                

                else if (!this.navigated) {

                    this.breadcrumbs.push({
                        name: node.data['breadcrumb'],
                        url: '/' + url
                    })
                }
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
        this.navigated = true;
    }


    navigate(breadcrumb) {

        if (breadcrumb.url.includes("/pr")) {

            let path = breadcrumb.url.split("/")[0]

            this.commonService.goToCategory(breadcrumb.name, parseInt(path))
        } else {
            this.router.navigate([breadcrumb.url]);
        }
    }

    getConfigData() {
        this.configService.getConfigData()
          .subscribe(
            data => {
              this.breadcrumbType = data['breadcrumbType'];
             // console.log(this.breadcrumbType);
              
              }, error => {
    
              });
    }




}
