<div *ngIf="disableCartIconInHeader==0">
    <button fxFlex *ngIf="(url!='/cart' && url!='/checkout'
    && url!='/checkout/type')"  
    (click)="cart()" mat-button class="flex-row-button">
        <mat-icon  [ngStyle.gt-md]="{ 'color': !this.color ? 'white' : 'black' }" >add_shopping_cart</mat-icon>
    
        <span *ngIf="cartServices.cartCount>0" class="cart-items-count">
            {{ cartServices.cartCount }}</span>
    
            
    </button>
</div>


