import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';

@Component({
  selector: 'calsoft-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss']
})
export class CartIconComponent implements OnInit {
  page1: string;
  url: string;
  guestLoginEnabled: string;
  userLoggedIn: boolean;
  @Input() color: boolean=true;
  disableCartIconInHeader: any=0;

  constructor( private router: Router,
    private userService: UserService,
    public cartServices: CartService,
    private dialog: MatDialog,
    private configService: ConfigServiceService,
    ) { }

  ngOnInit(): void {
    this.url = this.router.url;
    this.guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    this.userLogged();
    this. getConfigData();
  }

  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();
   
 
    if (this.guestLoginEnabled=="1"||(basicAuthHeaderString && username)) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }

    userLogged() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {

      this.userLoggedIn = true;
      this.cartServices.getCount();

    }else if(this.guestLoginEnabled=="1"){
      this.cartServices.getCount();
    }
    else {

      this.userLoggedIn = false;
    }
  }

  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '790px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }

  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.disableCartIconInHeader=data['disableCartIconInHeader'];
        }, error => {

        }
      );
  }

}
