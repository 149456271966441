import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { MatDialog } from '@angular/material/dialog';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { ConfigService } from 'src/app/services/config.service';
import { MenuItems } from 'src/app/Core/menu/menu-items/menu-items';

declare var $: any;

@Component({
  selector: 'calsoft-header30',
  templateUrl: './header30.component.html',
  styleUrls: ['./header30.component.scss']
})
export class Header30Component {

  @Input() type: any;
  @Input() containerClass = "container";
  public serverPath = environment.commonImageApi + "logo/";
  @Input() data: any;
  toggleActive: boolean = false;
  cartProducts: any;
  popupResponse: any;
  wishlistProducts: any;
  userLoggedIn: boolean;
  page: string;
  page1: any;
  cartTotalItems: any;
  public form: UntypedFormGroup;
  url: string;
  countryCode: Subject<any> = this.loaderService.countryCode;
  index: any;
  @Input() item: any;
  @Input() languageSelection: any;
  mobileHeaderType: any = 0;
  wishCount = 0;
  display: boolean;
  showFooterIcon: any = 0;
  footerIconData: any=[];
  menuData: any=[];

  constructor(public activeRoute: ActivatedRoute,
    public calsoftService: CalsoftService,
    private router: Router,
    private basicAuthenticationService: UserService,
    private dialog: MatDialog,
    private userService: UserService,
    public cartServices: CartService,
    private loaderService: LoaderService,
    private configService:ConfigService,
    public menuItems: MenuItems,
    private location: Location) {
  }

  ngOnInit() {
    this.userLogged();
    this.url = this.router.url;

    if (window.innerWidth < 1281) {
      this.display = false;
    } else {
      this.display = true
    }
    this.mobileHeaderType = this.data['mobileHeaderType'];
    this.getFooterMenuData()
    if (this.data.showFooterIcon == 1) {

      this.showFooterIcon = 1;
      this.getFooterIcon();
    }

  }


  composeEmail(smtpUsername) {
    window.location.href = 'mailto:' + smtpUsername;
  }
  
  callPhone() {
    let phoneNumber = "tel:" + this.data.storePhoneNumber;
    window.location.href = phoneNumber;
  }

  showLoginModal(event: Event): void {
    event.preventDefault();
    //	this.modalService.showLoginModal();
  }

  userLogged() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
    let username = this.basicAuthenticationService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {

      this.userLoggedIn = true;
      this.cartServices.getCount();

    }
    else {

      this.userLoggedIn = false;
    }
  }

  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '790px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }

  }

  back() {
    this.location.back();
  }


  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }

  wishlist() {
		if (this.userLoggedIn) {
			this.userLoggedIn = true
			this.router.navigate(['/account/wishlist']);  
		  }
		  else {
			this.userLoggedIn = false
			this.loginModel();
			
		  }
		
	  }

  SignIn() {
    // this.router.navigate(['/sign-in']);
    this.loginModel();
  }

  home() {
    this.router.navigate(['/'])
  }


  search() {
    this.router.navigate(['/search'])
  }

  public toggleSearch() {
    $('app-main').toggleClass('form-open');
  }

  public toggleSidebar() {
    this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  }


  public selectedCurrency(value) {
    this.calsoftService.currency = value;
  }

  public selectedLanguage(value) {
    this.calsoftService.language = value;
  }

  getFooterMenuData() {

    if ((this.data.showContactPage == 1 && this.data.showBlogPage == 0)) {
      this.menuData = this.menuItems.getFooterOneMenu()
    } else if (this.data.showContactPage == 0 && this.data.showBlogPage == 0) {
      this.menuData = this.menuItems.getFooterTwoMenu()
    } else if (this.data.showContactPage == 0 && this.data.showBlogPage == 1) {
      this.menuData = this.menuItems.getFooterThirdMenu()
    } else if (this.data.showContactPage == 1 && this.data.showBlogPage == 1) {
      this.menuData = this.menuItems.getFooterFourthMenu()
    }
  }

  getFooterIcon() {
    this.configService.getFooterData()
      .subscribe(
        data => {
          if (data['datalist'].length == 0) {
            this.showFooterIcon = 0;
            this.footerIconData = [];
          } else {
            this.footerIconData = data['datalist'];
          }

        }, error => {

        }
      );
  }

  navigate(data:any) {

    if(data.linkNavigated==1){
    
      if(data.link!=null){
        let   url = data.link
        window.location.href=url;
      }
      
    }
  }

}


