import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'calsoft-editor-text',
  templateUrl: './editor-text.component.html',
  styleUrls: ['./editor-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorTextComponent implements OnInit {
  trustedHtmlContent: SafeHtml;
  @Input() text: any;

  @Input() align: any;

  @Input() data: any;

  @Input() fontSize: any;

  @Input() MobfontSize: any;

  @Input() fontFamily: any;

  constructor(
   private sanitizer: DomSanitizer,
   

  ) {
    
  }

  ngOnInit() {
   
    this.trustedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

}
