import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Location } from '@angular/common';
import { ProductService } from 'src/app/services/product.service';
import { CommonService } from 'src/app/services/common.service';

import { LocationService } from 'src/app/services/location.service';
import { Subscription } from 'rxjs';
import { FormBuilder} from '@angular/forms';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MapLocationComponent } from '../map-location/map-location.component';

declare const google: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  host: {
    'class': 'map-dialog'
  }
})
export class LocationComponent implements OnInit{
  isSubmitted = false;
  locationSelected=false;
  val: any = '';
  search: any;
  searchText: any = '';
  searchRequest: any;
  filteredOptions: any;

  size: number;
  page: number;

  sortParam: string;
  productName: any;
  empty: any;
  focused: boolean;
   
  query: any;
  places: any[] = [];fonSearchChange
  placeSub: Subscription;
  mapLocationTitle:any;
  mapLocationAddress:any;
  firstFormGroup = this.formBuilder.group({
   
    search:['']
    
  });
  separatedAddress:any;
  commonData: any;
  type: number;
  title: any;
  buttonTitle: string;
  showCloseButton: boolean=false;
  constructor(
    public _location: Location,
    public productService: ProductService,
    private commonService: CommonService,
    public formBuilder: FormBuilder,
    private maps: GoogleMapService,
    private locationService: LocationService,
    public router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MapLocationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) { 
    this.commonData = data


    //console.log(data);
    
    if(data['page']=="address"){
      this.showCloseButton=true;
      this.buttonTitle="SAVE & PROCEED"
    }else{
      this.buttonTitle="CONFIRM & PROCEED"

    }
    
  }

  ngOnInit() {

    let lat= localStorage.getItem('latitude');
    let long= localStorage.getItem('longitude');
    if(lat!=null&&long!=null){

      this.locationSelected=true
    }

    this.placeSub = this.maps.places.subscribe(places => {
      this.places = places;
     //console.log(this.firstFormGroup);
    });    

  }




  async onSearchChange(event) {
    console.log(this.firstFormGroup)
    this.query = event.target.value
    if(this.query.length>0)
     await this.maps.getPlaces(this.query,this.commonData['fullDat']['googleApi']);
  }
  
   
  clearSearch() {
    this.places = [];
    this.firstFormGroup.reset();
  }

  
  onKeydown(event) {
    let value1=event.target.value;
    value1= value1.replace(/AND/g, 'asasfas');
    value1= value1.replace(/OR/g, 'bhhasfas');
    if (event.target.value.trim().length > 1) {
      this.saveSearchKeyWords(event.target.value);
      this.commonService.productSearch(value1)
    }
  }
  
  
  saveSearchKeyWords(i) {
  
    let search = {
      "queryText": i,
      "numResults": 0,
      "popularity": 0,
      "redirect": null,
      "synonymFor": null,
      "storeId": 1,
      "displayInTerms": 1,
      "isActive": 0,
      "isProcessed": 0,
      "operationType": "New"
    }
  
    this.commonService.saveSearchKeyWords(search).subscribe(
      (response) => {
  
      },
      (err) => { });
  
  }


  userPickLocation(place){

    localStorage.setItem('latitude', place.lat);
    localStorage.setItem('longitude', place.lng);
    localStorage.setItem('title', place.title);

    this.title=place.title;
    this.type=1;
        this.locationSelected=true;
  }

  setLocationSelected(){

    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('longitude');

    this.locationSelected=false;
  }
  
    userGetLocation(){
      let options: any = {
        maximumAge: 0,
        timeout: 5000,
        enableHeightAccuracy: true
      };
  
      navigator.geolocation.getCurrentPosition((resp:any) => {

        localStorage.setItem('latitude', resp.coords.latitude);
        localStorage.setItem('longitude', resp.coords.longitude);
        this.type=0;
        this.locationSelected=true;
      },
      (error) => {
        console.error('Error getting location:', error);
      },
      options
    );
    }


    async getAddress(lat, lng) {
      try {
        const result = await this.maps.getAddress(lat, lng,this.commonData['fullDat']);
        //console.log(result);
        const addressComponents = result.address_components;

        let previousValue='';
        this.separatedAddress = {
          address: '',
          city:'',
          district:'',
          state: '',
          country:'',
          pincode: '',
          full_address:result.formatted_address,
          longitude:result.geometry.location.lng,
          latitude:result.geometry.location.lat,    
          title:'',     
        };
  
        addressComponents.forEach((component: any) => {
       
          
          const types = component.types;
          if (types.includes('street_number') || types.includes('route') || types.includes('neighborhood') || types.includes('sublocality')) {
            this.separatedAddress.address += component.long_name + ' ';
          } else if (types.includes('locality')) {
            this.separatedAddress.city = component.long_name;          
          } else if (types.includes('administrative_area_level_3')) {
            this.separatedAddress.district = component.long_name;          
          } else if (types.includes('administrative_area_level_1')) {
            this.separatedAddress.state = component.long_name;
          } else if (types.includes('country')) {
            this.separatedAddress.country = component.long_name;
          } else if (types.includes('postal_code')) {
            this.separatedAddress.pincode = component.long_name;
          }
          if(previousValue=='route'){
            localStorage.setItem('title', component.long_name);
            this.separatedAddress.title = component.long_name;
          }
          previousValue=types;
        });
        console.log( this.separatedAddress);
      } catch(e) {
        console.log(e);
      }
    }


    addressdata(data){
      console.log(data);
    //  this.maps.sendDataPost({Name:"mapsAddress", data:data}); 
    this.dialogRef.close({ event: 'add', data: data})
    }

    removeText(){
      this.firstFormGroup.value.search='';
      this.firstFormGroup.patchValue(this.firstFormGroup.value);

    }

    closePopup(){

      this.dialogRef.close({ event: 'close'})

    }
}
