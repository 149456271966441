<div fxLayout.xs="column" fxLayout="row" fxLayoutAlign="center center">
    <div class="content-step" style="margin-left: 100;">
        <div class="content-step" fxFlex="250px" fxLayoutGap="60px" class="footlist-new">
            <h5>Quick Links</h5>
            <ul>
                <li><a href="/product-consulting-services-strategy/">Product Consulting</a></li>
                <li><a href="/product-assessment-strategy/">Product Assessment Strategy</a></li>
                <li><a href="/software-redesign-service/">Software Redesign Service</a></li>
                <li><a href="/devops-consulting-services/">Devops Consulting</a></li>
                <li><a href="/white-labeling-services/">White-Labelled Services</a></li>
                <li><a href="/blog/">Blog</a></li>
                <p class="mt-2"><a routerLink="/aboutus">About Us</a></p>
            </ul>
        </div>
    </div>

    <div class="content-step" fxFlex="300px" fxLayoutGap="50px" class="footlist-new">
        <h5>Email Us</h5><br>
        <h4>For Business Enquiries</h4>
        <span style="cursor: pointer;"
            (click)="composeEmail(data.smtpUsername)">&nbsp;&nbsp;{{data.smtpUsername}}</span>
        <h4>For Marketing Enquiries</h4>
        <span style="cursor: pointer;"
            (click)="composeEmail(data.smtpUsername)">&nbsp;&nbsp;{{data.smtpUsername}}</span>
        <h4>For Job Enquiries</h4>
        <span style="cursor: pointer;"
            (click)="composeEmail(data.smtpUsername)">&nbsp;&nbsp;{{data.smtpUsername}}</span>
    </div>
    <div class="content-step" fxLayoutGap="200px" class="footlist-new">
        <h5>Call Us</h5><br>
        <h4>India</h4>
        <span class="footer-color" style="margin-left: 8px; display: block;">{{ data.storePhoneNumber }}</span>
        <h4>United States</h4>
        <span class="footer-color" style="margin-left: 8px;">{{ data.storePhoneNumber }}</span>
        <h4>Whatsapp</h4>
        <span class="footer-color" style="margin-left: 8px;">{{ data.storePhoneNumber }}</span>
    </div>
    <div fxLayoutGap="50px" class="footlist-new">
        <h5>Address</h5><br>
        <h4>India</h4>
        <p class="m-0"> <strong>Calsoft Software Solutions Pvt Ltd</strong> </p>
        <span *ngIf="data.googleMapEnable==1">
            <span style="cursor: pointer;"
                (click)="getDirections(data.googleMapNavigation)">{{data.streetAddress}}</span>
        </span>
        <span *ngIf="data.googleMapEnable==0">{{data.streetAddress}}</span>
        <h4>United States</h4>
        <p>Chenni</p>
    </div>
</div>

<div class="content-step" fxLayout="row" fxLayoutAlign="center center">
    <hr fxFlex="1700px" style="border: 0; border-top: 1px solid  #111; margin: 10px 0;">
</div>
<div class="content-step" fxLayout="row" fxLayoutAlign="center center">
    <div class="row">
        <div class="col-lg-3">
            <div fxLayoutGap="20px"> <a href="https://www.facebook.com/zaigoinfotechsoftwaresolution/" target="_blank">
                    <img src="assets/icons/facebook.svg" alt="Facebook Logo Image" title="Facebook" width="43"
                        height="43" class=" lzl-cached lzl-ed"
                        data-lzl-src="https://zaigoinfotech.com/wp-content/uploads/2024/svg/Facebook.svg"></a>
                <span fxLayoutGap="20px"><a href="https://twitter.com/zaigoinfotech" target="_blank"> <img
                            src="assets/icons/twitter.svg" alt="Twitter Logo Image" title="Twitter" width="43"
                            height="43"
                            data-lzl-src="https://zaigoinfotech.com/wp-content/uploads/2024/svg/Twitter.svg"></a></span>
                <span fxLayoutGap="20px"><a href="https://in.linkedin.com/company/zaigo-infotech-software-solutions"
                        target="_blank" title="Linkedin"> <img src="assets/icons/linkedin.svg" alt="Linkedin Logo Image"
                            width="43" height="43" class=" lzl-cached lzl-ed"
                            data-lzl-src="https://zaigoinfotech.com/wp-content/uploads/2024/svg/Linkedin.svg"></a></span>
                <span fxLayoutGap="20px"><a href="https://www.instagram.com/zaigo_infotech/" target="_blank">
                        <img src="assets/icons/instagram.svg" alt="Instagram Logo Image" title="Instagram" width="43"
                            height="43"
                            data-lzl-src="https://zaigoinfotech.com/wp-content/uploads/2024/svg/Instagram.svg"></a></span>
                <span fxLayoutGap="20px"><a href="https://www.youtube.com/channel/UCaIRgcc7edGk6tiIe2X__vA"
                        target="_blank"> <img src="https://zaigoinfotech.com/wp-content/uploads/2024/svg/Youtube.svg"
                            alt="Youtube Logo Image" title="Youtube" width="43" height="43"
                            data-lzl-src="assets/icons/youtube.svg"></a></span>
                <a fxLayoutGap="100px">© 2015-2024 All Rights Reserved - Calsoft </a>
                <a routerLink="/privacy">Privacy
                    Policy</a>

            </div>

        </div>
    </div>
</div>