import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';
import { ThisReceiver } from '@angular/compiler';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LocationMapService {

  url = environment.serverAPI4 + "/api/showLocation/dropdown"; 

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
  ) { }


  getMaplocation(): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
    
      return this.http.get(`${this.url}`);
    }else{
      return this.http.get(`${this.url}`);
    }
  }


}
