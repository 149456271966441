<form [formGroup]="firstFormGroup" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4"
ngClass.sm="mt-2" ngClass.xs="mt-2">
<input style="letter-spacing: 1px;" type="text" class="text" formControlName="subscriberEmail" placeholder=" Enter Your Email.. "
    fxFlex>
<mat-error *ngIf="firstFormGroup.hasError('subscriberEmail')">jjhv</mat-error>

<button [disabled]="firstFormGroup.invalid" 
  style="padding: 12px;"
  (click)="subscribe()" type="button"
    class="text-muted">
     &nbsp;Subscribe</button>
  
</form>