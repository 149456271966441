<div class="appLogo pointer gap" fxFlex="100" fxLayoutAlign="center center">

  <div *ngIf="type=='dark'; else normalImage">
    <a (click)="closeMenu()">
 
      <img  [alt]="sitename"  class="isa" fxFlex="100" fxLayoutAlign="center center"
      [defaultImage]="defaultImage"
      [lazyLoad]="serverPath+'logo.png'"   onerror="this.src='assets/images/error/logoerror1.png';this.style.width='80px';" />

  </a>
</div>


  <ng-template #normalImage>

    <a (click)="closeMenu()">
          <img  [alt]="sitename"  class="isa" fxFlex="100" fxLayoutAlign="center center"
          [defaultImage]="defaultImage"
          [lazyLoad]="serverPath+'logoBG.png'"   onerror="this.src='assets/images/error/logoerror1.png';this.style.width='80px';" />
  </a>
    </ng-template>

  </div>