import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, HostBinding, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationExtras } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
     trigger('indicatorRotate', [
        state('collapsed', style({ transform: 'rotate(0deg)' })),
        state('expanded', style({ transform: 'rotate(180deg)' })),
        transition('expanded <=> collapsed',
           animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
        ),
     ])
  ]
})
export class SideBarMenuComponent implements OnInit {

  public serverPath = environment.commonImageApi + 'category/small_image/70x70/';
  appPages :any=[];
  expanded: boolean=true;
  @Input() sideMenuItem: any;
  @Input() data: any;
  @Input() depth: number;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  page1: any;
  userLoggedIn: boolean;
  username:any;
  displayNameFully:any=false;
   referer: string;

  constructor(public router: Router,
     public calsoftService: CalsoftService,
     public basicAuthenticationService: UserService,
     private userService: UserService,
     private dialog: MatDialog,
     private snackBar:MatSnackBar,
     private commonService:CommonService,
     private cartServices:CartService

  ) {


  }

  ngOnInit() {

  this.referer=Constant.REFERER;
   if(this.data!=undefined){
      if(this.data['displayAccountName']!=undefined){
      this.displayNameFully=this.data['displayAccountName']=="1"?true:false;
        this.username = this.userService.getAuthenticatedUser();
      }
    }

   
     this.userLogged();

     if((this.data.showContactPage==1&&this.data.showBlogPage==0)){

      this.appPages=this.appPages2
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==0 ){
      this.appPages=this.appPages1
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==1 ){
      this.appPages=this.appPages3
    }else if(this.data.showContactPage==1 &&this.data.showBlogPage==1 ){
      this.appPages=this.appPages4
    }


    

  }

  // Inside your Angular component
  shortenUsername(username: string, maxLength: number): string {
   if (username.length <= maxLength) {
     return username;
   } else {
     const truncatedUsername = username.substring(0, maxLength);
     return truncatedUsername + '...';
   }
 }
 

  ngOnChanges(changes: SimpleChanges): void {
     this.userLogged();


     

  }

  account1(){
   this.router.navigate(['/account/info']);
   this.closeMenu();
  }

  appPages1 = [
     {
        title: 'My Cart',
        url: '/cart',
        icon: 'add_shopping_cart'
     },
     {
        title: 'My Orders',
        url: '/orders',
        icon: 'text_snippet'
     },
     {
        title: 'My Wishlist',
        url: '/account/wishlist',
        icon: 'favorite'
     },
     {
        title: 'My Account',
        url: '/account/info',
        icon: 'person'
     },
     {
        title: 'Coupon',
        url: '/coupon',
        icon: 'local_offer'
     },
  ];

  appPages2 = [
   {
      title: 'My Cart',
      url: '/cart',
      icon: 'add_shopping_cart'
   },
   {
      title: 'My Orders',
      url: '/orders',
      icon: 'text_snippet'
   },
   {
      title: 'My Wishlist',
      url: '/account/wishlist',
      icon: 'favorite'
   },
   {
      title: 'My Account',
      url: '/account/info',
      icon: 'person'
   },
   {
      title: 'Contact-Us',
      url: '/contact-us',
      icon: 'contact_page'
   },
   {
      title: 'Coupon',
      url: '/coupon',
      icon: 'local_offer'
   },
];

appPages3 = [
   {
      title: 'My Cart',
      url: '/cart',
      icon: 'add_shopping_cart'
   },
   {
      title: 'My Orders',
      url: '/orders',
      icon: 'text_snippet'
   },
   {
      title: 'My Wishlist',
      url: '/account/wishlist',
      icon: 'favorite'
   },
   {
      title: 'My Account',
      url: '/account/info',
      icon: 'person'
   },
   {
      title: 'Coupon',
      url: '/coupon',
      icon: 'local_offer'
   },
  {
      title: 'Blog',
      url: '/blog',
      icon: 'chat'
   },
   {
      title: 'Brochure',
      url: '/brochure',
      icon: 'menu_book',
    },
];

appPages4 = [
   {
      title: 'My Cart',
      url: '/cart',
      icon: 'add_shopping_cart'
   },
   {
      title: 'My Orders',
      url: '/orders',
      icon: 'text_snippet'
   },
   {
      title: 'My Wishlist',
      url: '/account/wishlist',
      icon: 'favorite'
   },
   {
      title: 'My Account',
      url: '/account/info',
      icon: 'person'
   },
   {
      title: 'Coupon',
      url: '/coupon',
      icon: 'local_offer'
   },
   {
      title: 'Contact-Us',
      url: '/contact-us',
      icon: 'contact_page'
   },
   {
      title: 'About-Us',
      url: '/aboutus',
      icon: 'info'
   },
     {
      title: 'Blog',
      url: '/blog',
      icon: 'chat'
   },
   {
      title: 'Brochure',
      url: '/brochure',
      icon: 'menu_book',
    },

];





  categories(subcategory, i) {

     let navigationExtras: NavigationExtras = {
        queryParams: {
           "subcategory": JSON.stringify(subcategory),
        },

     };
     this.calsoftService.sidenavOpen = false;

     this.router.navigate(['/categories'], navigationExtras);
  }



  loginModel() {
     if (window.innerWidth > 768) {

        let dialogRef = this.dialog.open(CommonSignInComponent, {
           width: '790px',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.ngOnInit();


        });

     }
     else {
        let dialogRef = this.dialog.open(CommonSignInComponent, {
           minWidth: '100vw', height: '100vh',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {


           if (result['event'] != 'close') {
              this.router.navigate([this.page1])
           }

        });
     }

  }


  account(i) {
     let basicAuthHeaderString = this.userService.getAuthenticatedToken();
     let username = this.userService.getAuthenticatedUser();
     this.page1 = i;
     let authourized = this.userService.getAuthenticatedUser();
     let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
     if (i=='/cart'&&(authourized==null&&guestLoginEnabled=="0")){
      this.loginModel();
     }
    else if (i=='/cart'&&(authourized==null||guestLoginEnabled=="1")){
      this.router.navigate([this.page1])
     }
     else if(i=='/blog'||i=='/coupon'||i=='/aboutus'||i=='/brochure'){
      this.router.navigate([this.page1])
     }
     else if (basicAuthHeaderString && username) {
        this.router.navigate([this.page1])
     }
     else {

        this.loginModel();

        // this.router.navigate(['/cart'])

     }
     this.calsoftService.sidenavOpen = false;

  }



  userLogged() {

     this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
     };

     let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
     let username = this.basicAuthenticationService.getAuthenticatedUser();

     if (basicAuthHeaderString && username) {

        this.userLoggedIn = true;
     }
     else {

        this.userLoggedIn = false;
     }
  }




  signOut() {
 
   this.userService.logout();
   localStorage.removeItem(Constant.CART_COUNT)
   this.cartServices.responseCache1 = new Map();
   localStorage.removeItem(Constant['CART_ID']);
   localStorage.removeItem(Constant.CART_COUNT)
   this.calsoftService.sidenavOpen = false;
   let authourized = this.userService.getAuthenticatedUser();
   let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])

    if (authourized==null||guestLoginEnabled=="1") {
   this.cartServices.getCount1();
    }
    this.cartServices.responseCache1 = new Map();
    this.basicAuthenticationService.userLogin=false;
   }


   goToCategory(menu) {

      this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
     this.calsoftService.sidenavOpen = false;

   }

   closeMenu(){
     this.calsoftService.sidenavOpen = false;
   }

   addToHomeScreen() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log('App is already installed');
  } else {
    console.log('App is not installed');
    const manifestLink = document.createElement('link');
    manifestLink.rel = 'manifest';
    manifestLink.href = '/manifest.webmanifest';
    document.head.appendChild(manifestLink);
    manifestLink.onload = () => {
      console.log('Manifest file loaded');
      const customButton = document.querySelector('.custom-button');
      //customButton.style.display = 'none';
    };
  }
}


  openCustomButton() {
    window.open('https://example.com/custom-button', '_blank');
  }
 



}
