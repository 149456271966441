import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { NewsletterPopupService } from 'src/app/services/newsletter-popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-newsletter-ads',
  templateUrl: './newsletter-ads.component.html',
  styleUrls: ['./newsletter-ads.component.scss']
})
export class NewsletterAdsComponent {

  firstFormGroup: UntypedFormGroup;

  @Input() subscribeText: any;

  public serverPath = environment.commonImageApi + "adsHomePageImage/";

  public serverPath1 = environment.commonImageApi + "logo/";

  adsHomePageImage: any;
  adsHomePageTitle: any;
  adsHomePageDescription: any;

  @Input() data: any;
  adsHomePageType: any;

  typeFormIdHomePageTitle:any;

  constructor(
    private configService: ConfigServiceService,
    private dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private aboutService: AboutService,
    private newsletterPopupService : NewsletterPopupService,
    ) {}

  close(){
    this.dialog.closeAll();
  }

  
  closePopup(): void {
    this.newsletterPopupService.hidePopup();
  }

  ngOnInit() {

    this.firstFormGroup = this.formBuilder.group({
      'subscriberId': ['',],
      'storeId': ['',],
      'changeStatusAt': ['',],
      'customerId': ['',],
      'subscriberEmail': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      'subscriberStatus': ['',],
      'subscriberConfirmCode': ['',],
    });

    this.getConfigData();

    this.loadTypeformScript();

  }


getConfigData() {
  this.configService.getConfigData()
    .subscribe(
      data => {

        this.adsHomePageImage=data['adsHomePageImage'];

        this.adsHomePageTitle=data['adsHomePageTitle'];

        this.adsHomePageDescription=data['adsHomePageDescription'];

        this.adsHomePageType=data['adsHomePageType'];

        this.typeFormIdHomePageTitle=data['typeFormIdHomePageTitle'];
        

      }, error => {

      }
    );
}

private loadTypeformScript() {
  const script = document.createElement('script');
  script.src = '//embed.typeform.com/next/embed.js';
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

subscribe() {
  this.firstFormGroup.value.storeId = 1;
  this.firstFormGroup.value.subscriberStatus = 1;
  this.firstFormGroup.value.operationType = "New";
  this.aboutService.subscriper(this.firstFormGroup.value).subscribe(
    response => {
      if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
        this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
        this.firstFormGroup.reset();
        this.dialog.closeAll();
      } else {
        this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
      }
    },
    (err) => { });
}

}
