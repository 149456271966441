import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';

@Component({
  selector: 'calsoft-menu-iteration',
  templateUrl: './menu-iteration.component.html',
  styleUrls: ['./menu-iteration.component.scss']
})
export class MenuIterationComponent implements OnInit {
  openMenu: boolean = false;
  @Input() data: any;
  appPages: any = [];

  constructor(public router: Router,
    public calsoftService: CalsoftService,
  ) { }

  ngOnInit(): void {

    // if ((this.data.disableRefundPolicy == 1 && this.data.disableShippingPolicy == 1 && this.data.disablePaymentPolicy == 1)) {
    //   this.appPages = this.appPages2
    // } else if (this.data.disableRefundPolicy == 0 && this.data.disableShippingPolicy == 1 && this.data.disablePaymentPolicy == 1) {
    //   this.appPages = this.disableRefundPolicy
    // } else if (this.data.disableShippingPolicy == 0 && this.data.disableRefundPolicy == 1 && this.data.disablePaymentPolicy == 1) {
    //   this.appPages = this.disableShippingPolicy
    // } else if (this.data.disablePaymentPolicy == 0 && this.data.disableShippingPolicy == 1 && this.data.disableRefundPolicy == 1) {
    //   this.appPages = this.disablePaymentPolicy
    // } else if (this.data.disablePaymentPolicy == 0 && this.data.disableShippingPolicy == 0) {
    //   this.appPages = this.disablePaymentPolicydisableShippingPolicy
    // }else if (this.data.disableRefundPolicy == 0 && this.data.disableShippingPolicy == 0) {
    //   this.appPages = this.disableRefundPolicydisableShippingPolicy
    // }else if (this.data.disablePaymentPolicy == 0 && this.data.disableRefundPolicy == 0) {
    //   this.appPages = this.disablePaymentPolicydisableRefundPolicy
    // }

  }

  open() {

    if (!this.openMenu) {
      this.openMenu = true;
    } else {
      this.openMenu = false;
    }


  }
  close() {
    this.openMenu = false;
  }


  appPages2 = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },
    {
      title: 'Shipping Policy',
      url: '/shippingPolicy',
      icon: 'text_snippet'
    },
    {
      title: 'Refund | Cancellation Policy',
      url: '/returnPolicy',
      icon: 'favorite'
    },
    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
    {
      title: 'Payment-Policy',
      url: '/paymentPolicy',
      icon: 'payments'
    },
  ]


  disableRefundPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },
    {
      title: 'Shipping Policy',
      url: '/shippingPolicy',
      icon: 'text_snippet'
    },
    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
    {
      title: 'Payment-Policy',
      url: '/paymentPolicy',
      icon: 'payments'
    },
  ]

  disableShippingPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },
    {
      title: 'Refund | Cancellation Policy',
      url: '/returnPolicy',
      icon: 'favorite'
    },
    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
    {
      title: 'Payment-Policy',
      url: '/paymentPolicy',
      icon: 'payments'
    },
  ]

  disablePaymentPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },
    {
      title: 'Shipping Policy',
      url: '/shippingPolicy',
      icon: 'text_snippet'
    },
    {
      title: 'Refund | Cancellation Policy',
      url: '/returnPolicy',
      icon: 'favorite'
    },
    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
  ]

  disablePaymentPolicydisableShippingPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },
    {
      title: 'Refund | Cancellation Policy',
      url: '/returnPolicy',
      icon: 'favorite'
    },
    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
  ]

  disableRefundPolicydisableShippingPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },

    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
    {
      title: 'Payment-Policy',
      url: '/paymentPolicy',
      icon: 'payments'
    },
  ]

  disablePaymentPolicydisableRefundPolicy = [
    {
      title: 'Privacy Policy',
      url: '/privacy',
      icon: 'add_shopping_cart'
    },

    {
      title: 'Terms & Conditions',
      url: '/terms&conditions',
      icon: 'person'
    },
    {
      title: 'Shipping Policy',
      url: '/shippingPolicy',
      icon: 'text_snippet'
    },
  ]

  goToUrl(url) {
    this.router.navigate([url]);
    this.calsoftService.sidenavOpen = false;
  }
}
