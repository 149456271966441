<div mat-dialog-title class="title-head" fxLayout="row" fxLayoutAlign="space-between center" color="primary">
  <span style="color: white;">
      {{popupText}}
  </span>
  <button (click)="close()" mat-icon-button>
      <mat-icon style="color: white;">
          close
      </mat-icon>
  </button>
</div>
<div class="mat-typography ">

  <div fxLayout="column" class="padding-10">

      <div fxFlex="100" class="form">
          <div fxFlex="100" *ngIf="init">
              <dynamic-form fxFlex="100" [fields]="regConfig" (submit)="submit($event)">
              </dynamic-form>
          </div>
      </div>

  </div>

</div>