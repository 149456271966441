import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';


@Pipe({
  name: 'currencyConvert'
})
export class CurrencyConvertPipe implements PipeTransform {

  currencyRate: any;
  countryCode: string;
  decimalDigit: any=2;



  constructor(private currencyConversionService: CurrencyConversionService,
    private currencyPipe: CurrencyPipe,
    private commonService:CommonService,
    private configService: ConfigServiceService
  ) {
    this.getConfigData();
  }

  transform(val: any): any {

    let integerDigits = 1; // Minimum number of integer digits
    let minFractionDigits = this.decimalDigit; // Minimum number of fraction digits
    let maxFractionDigits = this.decimalDigit;

    let numberFormat = `${integerDigits}.${minFractionDigits}-${maxFractionDigits}`;

    this.countryCode = this.currencyConversionService.getCountryCode();

    if(this.countryCode ==null){

      this.countryCode=this.commonService.getCurrencyValue()
    let finalValue=  this.currencyPipe.transform(val, this.countryCode,'symbol-narrow', numberFormat);
      return finalValue;
    }
    else if (this.countryCode == 'INR' || this.countryCode == null) {

    //   let finalValue: any = val;
    //   finalValue = this.currencyPipe.transform(finalValue, 'INR' );

    //  let seperatedValue=finalValue.split(finalValue.charAt(0))

    //  let firstLetter=seperatedValue[0];
    //  finalValue=finalValue.substring(0);
   let   finalValue=new Intl.NumberFormat('en-IN').format(val)

      return "₹"+finalValue;
    }
    else {
      this.currencyRate = this.currencyConversionService.getCurrencyValue();
      let finalValue: any = val * this.currencyRate;
      finalValue = this.currencyPipe.transform(finalValue, this.countryCode,'symbol-narrow',numberFormat);
      return finalValue;
    }

  }

  getConfigData() {
    this.configService.getConfigData().subscribe(
      data => {
        this.decimalDigit = data['decimalDigit'];
      
      }, 
      error => {
        // Handle error
      }
    );
  }

}
