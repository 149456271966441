import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor(
    private http: HttpClient,
    private userService: UserService,
    
    ) { }

	url = environment.serverAPI2 + "/api/core";

  url1 = environment.serverAPI2 + "/api/ig/core";


  normalUploadFiles(data: FormData, type: any): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
		return this.http.post(`${this.url}/normal/uploadMultipleFiles?type=${type}`, data);
    }else{
      return this.http.post(`${this.url1}/normal/uploadMultipleFiles?type=${type}`, data);
    }
  }


}
