import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterPipe } from './filter.pipe';

import { TextTransformPipe } from './texttransform.pipe';
import { UnderScoreTransformPipe } from './under-score-transform-pipe.pipe';
import { TranslatePipe } from './translate.pipe';
import { SplitbyHashPipe } from './splitby-hash.pipe';
import { CurrencyConvertPipe } from './currency-convert.pipe';
import { NoSanitizePipePipe } from './no-sanitize-pipe.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        SafePipe,
        SafeHtmlPipe,
        FilterPipe,
        CurrencyConvertPipe,
        TextTransformPipe,
        UnderScoreTransformPipe,
        TranslatePipe,
        SplitbyHashPipe,
        NoSanitizePipePipe,
    ],
    exports: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        SafePipe,
        SafeHtmlPipe,
        FilterPipe,
        CurrencyConvertPipe,
        TextTransformPipe,
        UnderScoreTransformPipe,
        TranslatePipe,
        SplitbyHashPipe,
        NoSanitizePipePipe
        
    ], providers:[
        CurrencyPipe
    ]
})
export class PipesModule { }
