import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import {
  catchError,
  finalize,
  switchMap,
  filter,
  take,
  tap,
  map,
  retry,
} from "rxjs/operators";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";

import { Router } from "@angular/router";

import { CookieService } from "src/app/services/cookie.service";
import { Constant } from "src/app/constants/constant";
import { CartService } from "src/app/services/cart.service";
import { CommonService } from "src/app/services/common.service";
import { LoaderService } from "src/app/services/loader.service";
import { UserService } from "src/app/services/user.service";

@Injectable()
export class CalsoftInterceptor implements HttpInterceptor {
  userLoggedIn: boolean;
  isRequested401 = false;
  constructor(
    private basicAuthenticationService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private cartServices:CartService
  ) {}
  public onlineFlag = navigator.onLine;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let url=this.router.url;

     if (
      url.includes("/checkout-appointments?sid=")
    ) {
      this.loaderService.hide();
    }
    else if (url=='/home') {
      this.loaderService.hide();
    }
    else if(url=='/category'){
      this.loaderService.hide();
    }
    else if (request.url.includes("api/core/download")) {
      this.loaderService.hide();
    }
    else if (request.url.includes("api/cart")) {
      this.loaderService.show();
    }
    else if (request.url.includes("api/ig/cart")) {
      this.loaderService.show();
    }

    else if (request.url.includes("api/core/download?fileName")) {
      this.loaderService.hide();
    }
   
    else if (request.url.includes("api/ig/cart")) {
      this.loaderService.show();
    }
    else if (request.url.includes("api/auth/changePassword")) {
      this.loaderService.show();
    }

   else if (request.url.includes("api/catalog/search")) {
      this.loaderService.hide();
    }else if (request.url.includes("api/auth/user/login")) {
      this.loaderService.show();
    }
    else if (request.url.includes("api/auth/forgetpassword")) {
      this.loaderService.show();
    }
    else if (request.url.includes("api/auth/password/verifyToken")) {
      this.loaderService.show();
    }
    else if(this.router.url.includes("/pr?sid=")){
      this.loaderService.hide();
    }
     else if (request.url.includes("api/catalog/home")) {
      this.loaderService.hide();
    } else if (this.router.url == "/home") {
      this.loaderService.hide();
    } else if (this.router.url == "/") {
      this.loaderService.hide();
    } else if (
      request.url ==
        "https://devapp.calsoftgroup.com/ecom-svc-catalog-1.0-SNAPSHOT/api/catalog/search?page=0&size=10&sort=price,DESC" ||
      this.router.url == "/orders" ||
      request.url.includes("home") == true
    ) {
      this.loaderService.hide();
    }
    else if (
      request.url.includes("api/config/pincode/filter?search=")
    ) {
      this.loaderService.hide();
    } else if (
      request.url.includes("api/ig/config/pincode/filter?search=")
    ) {
      this.loaderService.hide();
    }
  
    else if (
      request.url.includes("api/config/pincode/all/site?page=")
    ) {
      this.loaderService.hide();
    }
    else if (
      request.url.includes("api/ig/config/pincode/all/site?page=")
    ) {
      this.loaderService.hide();
    }

    else if (
      request.url.includes("api/catalog/cms/page?")
    ) {
      this.loaderService.hide();
    }

    else if (
      request.url.includes("api/catalog/categories/idx/nestedPostion")
    ) {
      this.loaderService.hide();
    }

    else {
      this.loaderService.show();
    }
    let basicAuthHeaderString = this.basicAuthenticationService.getStorageToken(
      Constant.X_XSRF_TOKEN
    );

 if (
      basicAuthHeaderString != "" &&
      Constant.DEVICE_TYPE == "MOBILE"
    ) {
      request = this.addToken(request);
    } else if (Constant.DEVICE_TYPE == "MOBILE") {
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          [Constant.DEVICE]: Constant.DEVICE_TYPE,
          //"Content-Type": "application/json;charset=utf-8",
          [Constant.ENTERPRISE_SITE]: Constant.REFERER,
        },
      });
    } else if (basicAuthHeaderString != "") {
      this.userLoggedIn = true;
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          [Constant.X_XSRF_TOKEN]: basicAuthHeaderString,
          //"Content-Type": "application/json;charset=utf-8",
        },
      });
    } else {
      this.userLoggedIn = false;
      request = request.clone({
        withCredentials: true,
      });
      this.userLoggedIn = false;
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loaderService.hide();
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.loaderService.hide();
            if (err.status === 410) {
              return this.handle401Error(request, next);
            } else if (err.status === 401) {
              this.loaderService.hide();
              sessionStorage.clear();
              if (basicAuthHeaderString != "") {
                if (Constant.DEVICE_TYPE == "MOBILE") {
                  let lat= localStorage.getItem('latitude');
                  let long= localStorage.getItem('longitude');
                  let title=localStorage.getItem('title');
                  localStorage.clear();
                  localStorage.removeItem(Constant.CART_COUNT)
                  this.cartServices.responseCache1 = new Map();
                  if(lat!=null){
                  localStorage.setItem('latitude',lat);
                  localStorage.setItem('longitude', long);
                  localStorage.setItem('title', title);
                  this.router.navigate(["/"]);
                  }
                } else {

                  if(!this.isRequested401){
                    this.isRequested401=true;
                    this. cookieService.deleteAll();
                    localStorage.removeItem(Constant.CART_COUNT)
                    this.cartServices.responseCache1 = new Map();
                    this.basicAuthenticationService.logout().subscribe((response) => {
                      this.isRequested401=false;
                      this.isRefreshingToken = true;
                      this.router.navigate(["/"]);
                    });
                    sessionStorage.clear();
                  }
                  }

              } else {
                this.router.navigate(["/"]);
              }
            }
            if (err.status === 500 || err.status === 0) {
              this.loaderService.hide();
              this.router.navigate(["/error/500"]);
            } else if (!navigator.onLine) {
              this.loaderService.hide();
              this.router.navigate(["error/nointernet"]);
            }
          }
        }
      )
    );
  }

  addToken(request: HttpRequest<any>) {
    let basicAuthHeaderString = this.basicAuthenticationService.getStorageToken(
      Constant.X_XSRF_TOKEN
    );
    if (basicAuthHeaderString != "") {
      return request.clone({
        headers: new HttpHeaders({
          [Constant.X_XSRF_TOKEN]:
            this.basicAuthenticationService.getStorageToken(
              Constant.X_XSRF_TOKEN
            ),
          [Constant.XSRF_TOKEN]:
            this.basicAuthenticationService.getStorageToken(
              Constant.XSRF_TOKEN
            ),
          [Constant.DEVICE]: Constant.DEVICE_TYPE,
          [Constant.ENTERPRISE_SITE]: Constant.REFERER,
        }),
      });
    } else {
      return request;
    }
  }
  // Used for queued API calls while refreshing tokens
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  // Indicates our access token is invalid, try to load a new one
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Check if another call is already using the refresh logic
    if (!this.isRefreshingToken) {
      // Set to null so other requests will wait
      // until we got a new token!
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      this.basicAuthenticationService.currentAccessToken = null;

      this.basicAuthenticationService.getNewAccessToken().subscribe(
        (response) => {
          if (response["status_code"] == Constant.RESPONSE_SUCCESS) {
            const i = response["data"];
            this.basicAuthenticationService.storeAccessToken(i);
            this.tokenSubject.next(i);

            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = "reload";

            if (this.router.url.includes("/pr?sid=")) {
              let value = this.router.url.split("/pr?sid=");

              this.commonService.goToCategoryWithEncryptedKey(
                value[0].slice(0),
                value[1]
              );
            } else if (this.router.url.includes("?sid=")) {
              let value = this.router.url.split("?sid=");

              this.commonService.productSearch(value[0].slice(8));
            } else {
              this.router.navigate([this.router.url]);
            }
            this.isRefreshingToken = false;
            return next.handle(this.addToken(request)).pipe(retry(1));
          } else {
            this.isRefreshingToken = false;
            let lat= localStorage.getItem('latitude');
            let long= localStorage.getItem('longitude');
            let title=localStorage.getItem('title');
            localStorage.clear();
            this.router.navigate(["/"]);
            if(lat!=null){
            localStorage.setItem('latitude',lat);
            localStorage.setItem('longitude', long);
            localStorage.setItem('title', title);
            }
          }
        },
        (err) => {
          this.isRefreshingToken = false;
          let lat= localStorage.getItem('latitude');
          let long= localStorage.getItem('longitude');
          let title=localStorage.getItem('title');
          localStorage.clear();
          this.router.navigate(["/"]);
          if(lat!=null){
            localStorage.setItem('latitude',lat);
            localStorage.setItem('longitude', long);
            localStorage.setItem('title', title);
          }

        }
      );

      // // First, get a new access token
      // return this.basicAuthenticationService.getNewAccessToken().pipe(
      //   map((token: any) => {
      //     console.log(token)
      //     if (token) {
      //       // Store the new token
      //       const i = token.data;
      //       return this.basicAuthenticationService.storeAccessToken(i).pipe(
      //         map(_ => {
      //           // Use the subject so other calls can continue with the new token
      //           this.tokenSubject.next(i);

      //           // Perform the initial request again with the new token
      //           return next.handle(this.addToken(request));
      //         })
      //       );
      //     } else {
      //       // No new token or other problem occurred
      //       return of(null);
      //     }
      //   }),
      //   finalize(() => {
      //     // Unblock the token reload logic when everything is done
      //     this.isRefreshingToken = false;
      //   })
      // );
    } else {
      // "Queue" other calls while we load a new token
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // Perform the request again now that we got a new token!
          return next.handle(this.addToken(request));
        })
      );
    }
  }
}
